<template>
  <v-dialog
    v-model="editTextDialog"
    max-width="700"
  >
    <v-card>
      <v-card-text>
        <v-textarea
          v-model="rect.text"
          auto-grow
          outlined
          rows="1"
          row-height="15"
          label="Текст"
        />
        <v-row>
          <v-col cols="8">
            <v-select
              v-model="rect.textStyle.fontFamily"
              :items="fontFamily"
              label="Шрифт"
            />
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="rect.textStyle.fontSize"
              :items="fontSize"
              label="Размер шрифта"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="rect.textStyle.alignItems"
              :items="alignItems"
              label="Вертикальное выравнивание"
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="rect.textStyle.justifyContent"
              :items="justifyContent"
              label="Горизонтальное выравнивание"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="rect.textStyle.textAlign"
              :items="textAlign"
              label="Выравнивание текста"
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="italic"
              label="italic"
              style="font-style: italic"
              @change="addItalic"
            />
          </v-col>
        </v-row>
        <v-row justify="space-around">
          <v-col>
            <v-checkbox
              v-model="backgroundColor"
              label="Фон"
              @change="backgroundColor ? '' : rect.textStyle.backgroundColor=null"
            />
            <v-color-picker
              v-if="backgroundColor"
              v-model="rect.textStyle.backgroundColor"
              dot-size="25"
              swatches-max-height="200"
              mode="hexa"
            />
          </v-col>
          <v-col>
            <v-checkbox
              v-model="textColor"
              label="Цвет шрифта"
              @change="textColor ? '' : rect.textStyle.color=null"
            />
            <v-color-picker
              v-if="textColor"
              v-model="rect.textStyle.color"
              dot-size="25"
              swatches-max-height="200"
              mode="hexa"
            />
          </v-col>
          <v-col>
            <v-checkbox
              v-model="borderColor"
              label="Цвет рамки"
              @change="borderColor ? '' : rect.textStyle.borderColor=null"
            />
            <v-color-picker
              v-if="borderColor"
              v-model="rect.textStyle.borderColor"
              dot-size="25"
              swatches-max-height="200"
              mode="hexa"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="text-overline py-0"
          >
            Отступы
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="rect.textStyle.paddingLeft"
              label="слева"
              type="number"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="rect.textStyle.paddingTop"
              label="сверху"
              type="number"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model.number="rect.textStyle.paddingBottom"
              label="снизу"
              type="number"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model.number="rect.textStyle.paddingRight"
              label="справа"
              type="number"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="saveChanges"
        >
          закрыть
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'TextEditorDialog',
    props: ['value', 'rectInput'],
    data () {
      return {
        backgroundColor: !!this.rectInput.textStyle.backgroundColor,
        textColor: !!this.rectInput.textStyle.color,
        borderColor: !!this.rectInput.textStyle.borderColor,
        italic: this.rectInput.textStyle.fontStyle && this.rectInput.textStyle.fontStyle === 'italic',
        fontSize: this.genSequence(13, 100),
        fontFamily: [
          'Arial, sans-serif',
          'Verdana, sans-serif',
          'Helvetica, sans-serif',
          'Tahoma, sans-serif',
          'Trebuchet MS, sans-serif',
          'Times New Roman, serif',
          'Georgia, serif',
          'Garamond, serif',
          'Courier New, monospace',
          'Brush Script MT, cursive',
          'a_FuturaOrto',
        ],
        textAlign: [
          'left', 'center', 'right',
        ],
        alignItems: [
          'flex-start', 'center', 'flex-end',
        ],
        justifyContent: [
          'left', 'center', 'right',
        ],
      }
    },
    computed: {
      editTextDialog: {
        get () {
          return this.value
        },
        set (newval) {
          this.$emit('input', newval)
        },
      },
      rect: {
        get () {
          return this.rectInput
        },
        set (newval) {
          this.$emit('updateRect', newval)
        },
      },
    },
    methods: {
      addItalic (newVal) {
        if (newVal) {
          this.rect.textStyle.fontStyle = 'italic'
        } else {
          this.rect.textStyle.fontStyle = 'normal'
        }
      },
      saveChanges () {
        // this.$emit('saveChanges', this.rect)
        this.editTextDialog = false
      },
      changeBgColor (val) {
        this.rect.textStyle.backgroundColor = val.hexa
      },
      changeTextColor (val) {
        this.rect.textStyle.color = val.hexa
      },
      genSequence (from, to) {
        const numbers = []
        for (let i = from; i <= to; i++) {
          numbers.push(i)
        }
        return numbers
      },
    },
  }
</script>
