<template>
  <div v-if="selVols">
    <v-select
      v-if="!hardAssignmentsChart && selectedChart"
      v-model="selectedChart"
      :items="chpatternIDs"
      outlined
      class="mt-5"
      label="График"
      @change="changePattern"
    >
      <template #selection="{ item }">
        {{ chpatterns[item].title ? chpatterns[item].title : item }}
      </template>
      <template #item="{ item }">
        {{ chpatterns[item].title ? chpatterns[item].title : item }}
      </template>
    </v-select>
    <dx-chart
      v-if="chpatterns"
      id="chart"
      :data-source="chartData"
      class="my-4"
      :animation="!printMode"
      :auto-hide-point-markers="false"
      :redraw-on-resize="!printMode"
      :palette="palette"
      :synchronize-multi-axes="synchronizeChart"
      @pointClick="onPointClick"
      @initialized="saveGridInstance"
    >
      <dx-size
        v-if="innHeight"
        :height="innHeight"
      />
      <dx-common-axis-settings
        :label="{font: { size: axisFontSize }}"
      />
      <dx-common-series-settings
        argument-field="arg"
        :type="selChartType"
        hover-mode="allArgumentPoints"
        selection-mode="allArgumentPoints"
      />
      <dx-series
        v-for="val in selVols"
        :key="'val-'+ val.id"
        :value-field="val.id"
        :name="val.title || val.name"
        :color="val.color"
      />
      <template v-if="multiChart">
        <dx-series
          v-for="val2 in selVols2"
          :key="'val2-'+ val2.id"
          axis="2cnd"
          :type="selChartType2"
          :value-field="val2.id"
          :name="val2.title || val2.name"
          :color="val2.color"
        />
      </template>
      <dx-value-axis
        :show-zero="showZero"
        :visualRange="{ startValue: rangeStart1, endValue: rangeEnd1 }"
      >
        <dx-grid
          :visible="true"
        />
      </dx-value-axis>
      <template v-if="multiChart">
        <dx-value-axis
          :visualRange="{ startValue: rangeStart2, endValue: rangeEnd2 }"
          v-for="val2 in selVols2"
          :key="'val2-ax-'+ val2.id"
          name="2cnd"
          position="right"
          :show-zero="showZero2"
        >
          <dx-grid :visible="false" />
        </dx-value-axis>
      </template>
      <dx-legend
        vertical-alignment="bottom"
        horizontal-alignment="center"
        :font="{ size: legendFontSize }"
      />
      <dx-tooltip
        :enabled="false"
      />
    </dx-chart>
    <v-expansion-panels
      v-if="rightAdmin"
      v-model="panel"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="pt-0">
          настройки графика
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field
            v-model="chpatternTitle"
            label="Название графика"
          />
          <v-select
            v-model="selChartType"
            :items="chartTypes"
            label="Тип отображения графика"
          />
          <v-row>
            <v-col
              v-show="false"
              cols="3"
            >
              <v-switch
                v-model="chartArgsAxisState"
                label="аргумент по колонке"
                disabled
                @change="clearArsVols"
              />
            </v-col>
            <v-col cols="3">
              <v-switch
                v-model="multiChart"
                label="multiChart"
                @change="clearArsVols"
              />
            </v-col>
            <v-col>
              <v-switch
                v-if="multiChart"
                v-model="synchronizeChart"
                label="Синхронизировать графики"
              />
            </v-col>
            <v-spacer />
            <v-col
              cols="1"
              class="mt-2 mr-3"
            >
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="orange"
                    v-bind="attrs"
                    @click="saveChartPattern"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-file-move-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Сохранить макет диаграммы</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="legendFontSize"
                :items="fontSizes"
                :menu-props="{ maxHeight: '400' }"
                label="Размер шрифта легенды"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="axisFontSize"
                :items="fontSizes"
                :menu-props="{ maxHeight: '400' }"
                label="Размер шрифта шкалы"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="rangeStart1"
                label="Значение диапазона от"
                type="number"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="rangeEnd1"
                label="Значение диапазона до"
                type="number"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8">
              <v-select
                v-model="maincol"
                :items="chartСols"
                item-text="name"
                return-object
                item-value="plid"
                :menu-props="{ maxHeight: '400' }"
                label="Основная колонка"
                persistent-hint
              />
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="showZero"
                label="Шкала от нуля"
              />
            </v-col>
          </v-row>
          <v-select
            v-model="selArgs"
            :items="chartArgs"
            item-text="name"
            return-object
            :menu-props="{ maxHeight: '400' }"
            label="Args"
            multiple
            persistent-hint
          >
            <template #prepend-item>
              <v-list-item
                ripple
                @click="toggleArgs"
              >
                <v-list-item-action>
                  <v-icon :color="selArgs.length > 0 ? 'indigo darken-4' : ''">
                    {{ iconArgs }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Select All
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2" />
            </template>
          </v-select>
          <v-select
            v-model="selVols"
            :items="chartVols"
            item-text="name"
            return-object
            :menu-props="{ maxHeight: '400' }"
            label="Vols"
            multiple
            persistent-hint
          >
            <template #prepend-item>
              <v-list-item
                ripple
                @click="toggle"
              >
                <v-list-item-action>
                  <v-icon :color="selVols.length > 0 ? 'indigo darken-4' : ''">
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Select All
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2" />
            </template>
            <template #prepend>
              <v-icon @click="volsOptions = !volsOptions">
                mdi-pencil
              </v-icon>
            </template>
          </v-select>
          <v-data-table
            v-if="volsOptions"
            :headers="volsOptionsHeaders"
            :items="selVols"
            class="new-table"
            hide-default-footer
          >
            <template #item="{ item }">
              <tr>
                <td>
                  <div style="padding: 8px; width: 100%; ">
                    {{ item.name }}
                  </div>
                </td>
                <td>
                  <v-select
                    v-model="item.color"
                    :items="swatches"
                    filled
                    hide-details
                    style="max-width: 180px"
                    dense
                  >
                    <template #item="itemColor">
                      <div
                        style="width: 100%; height: 22px;"
                        :style="' background-color:' + itemColor.item"
                      />
                    </template>
                    <template #selection="itemSel">
                      <div
                        style="width: 100%; height: 22px;"
                        :style="' background-color:' + itemSel.item"
                      />
                    </template>
                  </v-select>
                </td>
                <td>
                  <v-text-field
                    v-model="item.title"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
          <template v-if="multiChart">
            <v-divider class="my-6" />
            <v-select
              v-model="selChartType2"
              :items="chartTypes"
              label="Тип отображения графика"
            />
            <v-row>
              <v-col
                cols="3"
              >
                <v-switch
                  v-show="false"
                  v-model="chartArgsAxisState2"
                  label="аргумент по колонке"
                  disabled
                  @change="clearArsVols2"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="rangeStart2"
                  label="Значение диапазона от"
                  type="number"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="rangeEnd2"
                  label="Значение диапазона до"
                  type="number"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-select
                  v-model="maincol2"
                  :items="chartСols"
                  item-text="name"
                  return-object
                  :menu-props="{ maxHeight: '400' }"
                  label="Основная колонка"
                  persistent-hint
                />
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="showZero2"
                  label="Шкала от нуля"
                />
              </v-col>
            </v-row>
            <v-select
              v-model="selArgs2"
              :items="chartArgs2"
              item-text="name"
              return-object
              :menu-props="{ maxHeight: '400' }"
              label="Args"
              multiple
              persistent-hint
            >
              <template #prepend-item>
                <v-list-item
                  ripple
                  @click="toggleArgsAdd"
                >
                  <v-list-item-action>
                    <v-icon :color="selArgs2.length > 0 ? 'indigo darken-4' : ''">
                      {{ iconArgsAdd }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Select All
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2" />
              </template>
            </v-select>
            <v-select
              v-model="selVols2"
              :items="chartVols2"
              item-text="name"
              return-object
              :menu-props="{ maxHeight: '400' }"
              label="Vols"
              multiple
              persistent-hint
            >
              <template #prepend-item>
                <v-list-item
                  ripple
                  @click="toggleAdd"
                >
                  <v-list-item-action>
                    <v-icon :color="selVols2.length > 0 ? 'indigo darken-4' : ''">
                      {{ iconAdd }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Select All
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2" />
              </template>
              <template #prepend>
                <v-icon @click="volsOptions2 = !volsOptions2">
                  mdi-pencil
                </v-icon>
              </template>
            </v-select>
            <v-data-table
              v-if="volsOptions2"
              :headers="volsOptionsHeaders"
              :items="selVols2"
              class="new-table"
              hide-default-footer
            >
              <template #item="{ item }">
                <tr>
                  <td>
                    <div style="padding: 8px; width: 100%; ">
                      {{ item.name }}
                    </div>
                  </td>
                  <td>
                    <v-select
                      v-model="item.color"
                      :items="swatches"
                      filled
                      hide-details
                      style="max-width: 180px"
                      dense
                    >
                      <template #item="itemColor">
                        <div
                          style="width: 100%; height: 22px;"
                          :style="' background-color:' + itemColor.item"
                        />
                      </template>
                      <template #selection="itemSel">
                        <div
                          style="width: 100%; height: 22px;"
                          :style="' background-color:' + itemSel.item"
                        />
                      </template>
                    </v-select>
                  </td>
                  <td>
                    <v-text-field
                      v-model="item.title"
                    />
                  </td>
                </tr>
              </template>
            </v-data-table>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
  import DxChart, {
    DxSeries,
    DxLegend,
    DxTooltip,
    DxCommonSeriesSettings,
    // DxLabel,
    // DxFormat,
    DxCommonAxisSettings,
    DxValueAxis,
    DxGrid,
    DxSize
    } from 'devextreme-vue/chart'

  export default {
    components: {
      DxChart,
      DxLegend,
      DxSeries,
      DxTooltip,
      DxCommonSeriesSettings,
      // DxLabel,
      // DxFormat,
      DxCommonAxisSettings,
      DxValueAxis,
      DxGrid,
      DxSize,
    },
    props: {
      tableData: {
        type: Object,
        default: () => {},
      },
      rightAdmin: {
        type: Boolean,
        default: false,
      },
      printMode: {
        type: Boolean,
        default: false,
      },
      hardAssignmentsChart: {
        type: Number,
        default: null,
      },
      innHeight: {
        type: Number,
        default: null,
      },
    },
    data () {
      return {
        showZero: false,
        showZero2: false,
        swatches: [
          '#4F81BC', '#9BBB58', '#BE514C',
          '#F2CA84', '#A7CA74', '#C00000',
          '#FF0000', '#FFC000', '#FFFF00',
          '#92D050', '#00B050', '#00B0F0',
          '#0070C0', '#002060', '#7030A0',
        ],
        volsOptions: false,
        volsOptions2: false,
        volsOptionsHeaders: [
          {
            text: 'name',
            value: 'name',
          },
          {
            text: 'color',
            value: 'color',
          },
          {
            text: 'title',
            value: 'title',
          },
        ],
        legendFontSize: 20,
        axisFontSize: 20,
        dataGridInstance: null,
        palette: ['#4F81BC', '#9BBB58', '#BE514C', 'rgba(101,4,94,0.76)', '#F2CA84', '#A7CA74'],
        panel: 0,
        multiChart: false,
        synchronizeChart: false,
        selChartType: null,
        selChartType2: null,
        chartTypes: [
          'line', 'bar', 'stackedbar', 'spline',
          'area', 'fullstackedarea', 'fullstackedbar',
          'fullstackedline', 'fullstackedspline',
          'fullstackedsplinearea', 'rangearea', 'rangebar',
          'scatter', 'spline', 'splinearea', 'stackedarea',
          'stackedbar', 'stackedline', 'stackedspline',
          'stackedsplinearea', 'steparea', 'stepline',
        ],
        selVols: [],
        selArgs: [],
        maincol: [],
        chartArgsAxis: 'col',
        selVols2: [],
        selArgs2: [],
        maincol2: [],
        chartArgsAxis2: 'col',
        selectedChart: null,
        chpatternIDs: null,
        chpatterns: null,
        chpatternTitle: null,
        rangeStart1: null,
        rangeEnd1: null,
        rangeStart2: null,
        rangeEnd2: null,
      }
    },
    computed: {
      fontSizes () {
        const numbers = []
        for (let i = 12; i <= 45; i++) {
          numbers.push(i)
        }
        return numbers
      },
      chartToSave () {
        const getFirstIndex = (arr, person) => {
          return arr.findIndex(
            (a) => a.plid === person.plid)
        }
        const chartPattern = {
          args: this.selArgs.map((x) => { return { plid: x.plid } }).filter(
            (m, index, ms) => getFirstIndex(ms, m) === index),
          // vols: this.selVols.map((x) => { return { plid: x.plid } }).filter(
          //   (m, index, ms) => getFirstIndex(ms, m) === index),
          vols: this.selVols,
          chartType: this.selChartType,
          chartArgsAxis: this.chartArgsAxis,
          showZero: this.showZero,
          showZero2: this.showZero2,
          maincol: this.maincol,
          multiChart: this.multiChart,
          synchronizeChart: this.synchronizeChart,
          args2: this.selArgs2.map((x) => { return { plid: x.plid } }).filter(
            (m, index, ms) => getFirstIndex(ms, m) === index),
          // vols2: this.selVols2.map((x) => { return { plid: x.plid } }).filter(
          //   (m, index, ms) => getFirstIndex(ms, m) === index),
          vols2: this.selVols2,
          chartType2: this.selChartType2,
          chartArgsAxis2: this.chartArgsAxis2,
          maincol2: this.maincol2,
          title: this.chpatternTitle,
          legendFontSize: this.legendFontSize,
          axisFontSize: this.axisFontSize,
          rangeStart1: this.rangeStart1 || null,
          rangeEnd1: this.rangeEnd1 || null,
          rangeStart2: this.rangeStart2 || null,
          rangeEnd2: this.rangeEnd2 || null,
        }
        return chartPattern
      },
      chartData () {
        if (this.chartArgsAxisState) {
          return this.tableData.col.map((col) => {
            if (this.selArgs.length > 0 && this.selArgs.find((arg) => {
              return arg.plid === col.plcolid.toString()
            })) {
              const chartItem = {
                arg: col.title,
              }
              this.tableData.data.forEach((d) => {
                if (d[col.id].masked_value) {
                  if (d[col.id].datamask && d[col.id].datamask[1] === 'p') {
                    chartItem[d[col.id].plrowdataid] = parseInt(d[col.id].masked_value.replaceAll(' ', '').replaceAll('%', ''), 10) || 0
                  } else {
                    chartItem[d[col.id].plrowdataid] = parseInt(d[col.id].main_value, 10) || 0
                  }
                } else {
                  chartItem[d[col.id].plrowdataid] = 0
                }
              })
              return chartItem
            }
          }).filter((item) => {
            return item !== undefined
          })
        } else {
          return this.tableData.row.map((row) => {
            if (this.selArgs.length > 0 && this.selArgs.find((arg) => arg.plid === row.plrowid.toString())) {
              const chartItem = {
                arg: row.title || row.id,
              }
              const rowDataIndex = this.tableData.data.findIndex((rowData) => {
                const [first] = Object.keys(rowData)
                return rowData[first].plrowdataid === row.id
              })
              for (const key in this.tableData.data[rowDataIndex]) {
                chartItem[key] = parseInt(this.tableData.data[rowDataIndex][key].main_value / 100, 10)
                // chartItem[key] = parseInt(this.tableData.data[rowDataIndex][key].main_value / 100, 10)
              }
              return chartItem
            }
          }).filter((item) => {
            return item !== undefined
          })
        }
      },
      likesAll () {
        return this.selVols.length === this.chartVols.length
      },
      likesSome () {
        return this.selVols.length > 0 && !this.likesAll
      },
      icon () {
        if (this.likesAll) return 'mdi-close-box'
        if (this.likesSome) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      likesAllAdd () {
        return this.selVols2.length === this.chartVols.length
      },
      likesSomeAdd () {
        return this.selVols2.length > 0 && !this.likesAll
      },
      iconAdd () {
        if (this.likesAllAdd) return 'mdi-close-box'
        if (this.likesSomeAdd) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      likesAllArgs () {
        return this.selArgs.length === this.chartArgs.length
      },
      likesSomeArgs () {
        return this.selArgs.length > 0 && !this.likesAllArgs
      },
      iconArgs () {
        if (this.likesAllArgs) return 'mdi-close-box'
        if (this.likesSomeArgs) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      likesAllArgsAdd () {
        return this.selArgs2.length === this.chartArgs.length
      },
      likesSomeArgsAdd () {
        return this.selArgs2.length > 0 && !this.likesAllArgsAdd
      },
      iconArgsAdd () {
        if (this.likesAllArgsAdd) return 'mdi-close-box'
        if (this.likesSomeArgsAdd) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      chartArgsAxisState: {
        get () {
          return this.chartArgsAxis === 'col'
        },
        set (newVal) {
          this.chartArgsAxis = newVal ? 'col' : 'row'
        },
      },
      chartArgsAxisState2: {
        get () {
          return this.chartArgsAxis2 === 'col'
        },
        set (newVal) {
          this.chartArgsAxis2 = newVal ? 'col' : 'row'
        },
      },
      chartСols () {
        return this.tableData.col.map((col) => {
          if (col.coltype < 1) {
            return { id: col.id.toString(), name: col.title || col.id.toString(), plid: col.plcolid.toString() }
          }
        }).filter((item) => {
          return item !== undefined
        })
      },
      chartVols () {
        if (this.chartArgsAxisState) {
          return this.tableData.row.map((row, indx) => {
            if (row.sortindex && this.tableData.data[indx] && this.tableData.data[indx] && this.maincol && this.maincol.plid) {
              // eslint-disable-next-line camelcase
              let cellValue = null
              for (const key in this.tableData.data[indx]) {
                if (this.tableData.data[indx][key].pldcolid == this.maincol.plid) {
                  cellValue = this.tableData.data[indx][key]
                }
              }
              return { id: row.id.toString(), name: cellValue.masked_value, plid: row.plrowid.toString() }
              // return { id: row.id.toString(), name: cellValue.main_value, plid: row.plrowid.toString() }
            }
          }).filter((item) => {
            return item !== undefined
          })
        } else {
          return this.tableData.col.map((col) => {
            if (col.title && col.title.length > 0) {
              return { id: col.id.toString(), name: col.title, plid: col.plcolid.toString() }
            }
          }).filter((item) => {
            return item !== undefined
          })
        }
      },
      chartArgs () {
        if (this.chartArgsAxisState) {
          return this.tableData.col.map((col) => {
            if (col.title && col.title.length > 0) {
              return { id: col.id.toString(), name: col.title, plid: col.plcolid.toString() }
            }
          }).filter((item) => {
            return item !== undefined
          })
        } else {
          return this.tableData.row.map((row, indx) => {
            if (row.sortindex && this.tableData.data[indx] && this.tableData.data[indx] && this.maincol?.plid) {
              // eslint-disable-next-line camelcase
              let cellValue = null
              for (const key in this.tableData.data[indx]) {
                if (this.tableData.data[indx][key].pldcolid == this.maincol.plid) {
                  cellValue = this.tableData.data[indx][key]
                }
              }
              return { id: row.id.toString(), name: cellValue.masked_value, plid: row.plrowid.toString() }
            }
          }).filter((item) => {
            return item !== undefined
          })
        }
      },
      chartVols2 () {
        if (this.chartArgsAxisState2) {
          return this.tableData.row.map((row, indx) => {
            if (row.sortindex && this.tableData.data[indx] && this.tableData.data[indx] && this.maincol2?.plid) {
              // eslint-disable-next-line camelcase
              let cellValue = null
              for (const key in this.tableData.data[indx]) {
                if (this.tableData.data[indx][key].pldcolid == this.maincol2.plid) {
                  cellValue = this.tableData.data[indx][key]
                }
              }
              return { id: row.id.toString(), name: cellValue.masked_value, plid: row.plrowid.toString() }
            }
          }).filter((item) => {
            return item !== undefined
          })
        } else {
          return this.tableData.col.map((col) => {
            if (col.title && col.title.length > 0) {
              return { id: col.id.toString(), name: col.title, plid: col.plcolid.toString() }
            }
          }).filter((item) => {
            return item !== undefined
          })
        }
      },
      chartArgs2 () {
        if (this.chartArgsAxisState2) {
          return this.tableData.col.map((col) => {
            if (col.title && col.title.length > 0) {
              return { id: col.id.toString(), name: col.title, plid: col.plcolid.toString() }
            }
          }).filter((item) => {
            return item !== undefined
          })
        } else {
          return this.tableData.row.map((row, indx) => {
            if (row.sortindex && this.tableData.data[indx] && this.tableData.data[indx] && this.maincol2?.plid) {
              // eslint-disable-next-line camelcase
              let cellValue = null
              for (const key in this.tableData.data[indx]) {
                if (this.tableData.data[indx][key].pldcolid == this.maincol2.plid) {
                  cellValue = this.tableData.data[indx][key]
                }
              }
              return { id: row.id.toString(), name: cellValue.masked_value, plid: row.plrowid.toString() }
            }
          }).filter((item) => {
            return item !== undefined
          })
        }
      },
      flatCols () {
        const fCols = []
        this.tableData.col.forEach((col) => {
          if (col.children) {
            col.children.forEach((childcol) => {
              fCols.push(childcol)
            })
          } else {
            fCols.push(col)
          }
        })
        return fCols
      },
    },
    watch: {
      panel (newVal) {
        if (!this.printMode && this.rightAdmin && newVal === 0) {
          this.chpatterns = JSON.parse('{' + this.tableData.info.chpatternraw + '}')
          this.changePattern()
        } else {
          this.chpatterns = JSON.parse('{' + this.tableData.info.chpattern + '}')
          this.changePattern()
        }
      },
      rangeStart1 (val) {
        if (val === '') {
          this.rangeStart1 = null
        }
      },
      rangeEnd1 (val) {
        if (val === '') {
          this.rangeEnd1 = null
        }
      },

      rangeStart2 (val) {
        if (val === '') {
          this.rangeStart2 = null
        }
      },
      rangeEnd2 (val) {
        if (val === '') {
          this.rangeEnd2 = null
        }
      },
    },
    mounted () {
      if (this.tableData.info.chpattern) {
        if (!this.printMode && this.rightAdmin && this.panel === 0) {
          this.chpatterns = JSON.parse('{' + this.tableData.info.chpatternraw + '}')
        } else {
          this.chpatterns = JSON.parse('{' + this.tableData.info.chpattern + '}')
        }
        this.chpatternIDs = Object.keys(this.chpatterns)
        this.selectedChart = this.hardAssignmentsChart || this.chpatternIDs[0]
        this.chpatternTitle = this.chpatterns[this.selectedChart].title
        const chpattern = this.chpatterns[this.selectedChart]
        this.selChartType = chpattern.chartType
        this.chartArgsAxis = 'col' // chpattern.chartArgsAxis
        this.maincol = chpattern.maincol
        this.showZero = !!chpattern.showZero
        this.showZero2 = !!chpattern.showZero2
        this.multiChart = chpattern.multiChart
        this.synchronizeChart = chpattern.synchronizeChart
        this.selChartType2 = chpattern.chartType2
        this.chartArgsAxis2 = 'col' // chpattern.chartArgsAxis2
        this.maincol2 = chpattern.maincol2
        this.legendFontSize = chpattern.legendFontSize || 20
        this.axisFontSize = chpattern.axisFontSize || 20
        this.rangeStart1 = chpattern.rangeStart1 || null
        this.rangeEnd1 = chpattern.rangeEnd1 || null
        this.rangeStart2 = chpattern.rangeStart2 || null
        this.rangeEnd2 = chpattern.rangeEnd2 || null
        this.selArgs = this.chartArgs.map((x) => {
          if (~chpattern.args.findIndex(z => z.plid === x.plid)) {
            return x
          }
        }).filter((item) => {
          return item !== undefined
        })
        this.selVols = this.chartVols.map((x) => {
          const chpindex = chpattern.vols.findIndex(z => z.plid === x.plid)
          if (chpindex >= 0) {
            const vol = chpattern.vols[chpindex]
            vol.name = x.name
            vol.id = x.id
            return vol
          }
        }).filter((item) => {
          return item !== undefined
        })
        this.selArgs2 = this.chartArgs2.map((x) => {
          if (~chpattern.args2.findIndex(z => z.plid === x.plid)) {
            return x
          }
        }).filter((item) => {
          return item !== undefined
        })
        this.selVols2 = this.chartVols.map((x) => {
          const chpindex = chpattern.vols2.findIndex(z => z.plid === x.plid)
          if (chpindex >= 0) {
            const vol = chpattern.vols2[chpindex]
            vol.name = vol.name || x.name
            vol.id = x.id
            return vol
          }
        }).filter((item) => {
          return item !== undefined
        })
        const renderOptions = {
          force: true, // forces redrawing
          animate: false, // redraws the UI component without animation
        }
        if (this.dataGridInstance) {
          this.dataGridInstance.render(renderOptions)
        }
      }
    },
    methods: {
      changePattern () {
        const chpattern = this.chpatterns[this.selectedChart]
        this.chpatternTitle = this.chpatterns[this.selectedChart].title
        this.selChartType = chpattern.chartType
        this.chartArgsAxis = 'col' // chpattern.chartArgsAxis
        this.maincol = chpattern.maincol
        this.multiChart = chpattern.multiChart
        this.synchronizeChart = chpattern.synchronizeChart
        this.selChartType2 = chpattern.chartType2
        this.chartArgsAxis2 = 'col' // chpattern.chartArgsAxis2
        this.showZero = !!chpattern.showZero
        this.showZero2 = !!chpattern.showZero2
        this.maincol2 = chpattern.maincol2
        this.selArgs = this.chartArgs.map((x) => {
          if (~chpattern.args.findIndex(z => z.plid === x.plid)) {
            return x
          }
        }).filter((item) => {
          return item !== undefined
        })
        this.selVols = this.chartVols.map((x) => {
          const chpindex = chpattern.vols.findIndex(z => z.plid === x.plid)
          if (chpindex >= 0) {
            const vol = chpattern.vols[chpindex]
            vol.name = x.name
            vol.id = x.id
            return vol
          }
        }).filter((item) => {
          return item !== undefined
        })
        this.selArgs2 = this.chartArgs2.map((x) => {
          if (~chpattern.args2.findIndex(z => z.plid === x.plid)) {
            return x
          }
        }).filter((item) => {
          return item !== undefined
        })
        this.selVols2 = this.chartVols.map((x) => {
          const chpindex = chpattern.vols2.findIndex(z => z.plid === x.plid)
          if (chpindex >= 0) {
            const vol = chpattern.vols[chpindex]
            vol.name = x.name
            vol.id = x.id
            return vol
          }
        }).filter((item) => {
          return item !== undefined
        })
        const renderOptions = {
          force: true, // forces redrawing
          animate: false, // redraws the UI component without animation
        }
        this.dataGridInstance.render(renderOptions)
      },
      saveGridInstance (e) {
        this.dataGridInstance = e.component
      },
      clearArsVols () {
        this.selVols = []
        this.selArgs = []
      },
      clearArsVols2 () {
        this.selVols2 = []
        this.selArgs2 = []
      },
      saveChartPattern () {
        const payload = {
          plchartid: this.selectedChart,
          pltableid: this.tableData.info.pltmpltableid,
          pattern: JSON.stringify(this.chartToSave),
          descript: '',
          title: this.chpatternTitle,
        }
        this.$store.dispatch('changeTableChartPattern', payload).then(() => {
          const text = 'Данные обновлены'
          this.$store.dispatch('setSnackbar', { state: true, text: text, color: 'success', direction: 'top center' })
        })
      },
      toggle () {
        this.$nextTick(() => {
          if (this.likesAll) {
            this.selVols = []
          } else {
            this.selVols = this.chartVols.slice()
          }
        })
      },
      toggleArgs () {
        this.$nextTick(() => {
          if (this.likesAllArgs) {
            this.selArgs = []
          } else {
            this.selArgs = this.chartArgs.slice()
          }
        })
      },
      toggleAdd () {
        this.$nextTick(() => {
          if (this.likesAllAdd) {
            this.selVols2 = []
          } else {
            this.selVols2 = this.chartVols.slice()
          }
        })
      },
      toggleArgsAdd () {
        this.$nextTick(() => {
          if (this.likesAllArgsAdd) {
            this.selArgs2 = []
          } else {
            this.selArgs2 = this.chartArgs.slice()
          }
        })
      },
      onPointClick ({ target }) {
        target.select()
      },
    },
  }
</script>

<style>
#chart {
  width: 100%;
}
</style>
