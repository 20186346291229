<template>
  <span>
    <div style="position: relative; width: 100%; height: 100%">
      <div
        id="page-layout"
        class="page-layout-cont"
      >
        <div
          class="page-layout-cont-inner"
        >
          <div
            class="pages-side-bar"
            :style="fullscreen ? 'width: 0px' : ''"
          >
            <div
              class="pages-side-bar-inner"
            >
              <draggable
                v-model="pages"
                @change="dragChange"
              >
                <template v-for="(page, indx) in pages">
                  <div
                    :key="'page-mockup-' + indx"
                    class="page-mockup can-reorder"
                    :class="{ 'active-slide': indx === selectedPage?.pagenumber }"
                    style="display: block"
                    @click="choosePage(indx)"
                    @click.right.prevent="showPageDialog(indx)"
                  >
                    {{ page.pagetitle }} [{{ indx }}]
                    <div
                      class="edit-page"
                      @click.stop="showPageDialog(indx)"
                    >✎</div>
                    <div
                      class="del-page"
                      @click.stop="deletePage(indx)"
                    >x</div>
                  </div>
                </template>
              </draggable>
              <v-btn
                icon
                class="mr-0 mt-4"
                dark
                @click="showPageDialog()"
              >
                <v-icon large>
                  mdi-plus-circle-outline
                </v-icon>
              </v-btn>
            </div>
          </div>
          <div class="drag-zone-cont">
            <div
              v-if="selectedPage"
              id="page-1"
              class="page-layout"
              :style="`width: ${plwidth}px;height: ${plheight}px; left:49%; top:50%; transform: scale(${scaleVol}) ${scaleVolPrint?'':'translate(-50%, -50%)'};`"
            >
              <canvas
                class="canvas-grid"
                ref="canvasGrid"
              >
              </canvas>
              <div
                class="page-inner-layout"
                :style="pageStyle(selectedPage)"
                style="background-size: contain"
              >
                <div
                  class="page-header"
                />
                <vue-drag-resize
                  v-for="(rect, index) in selectedPage.pagepattern.data"
                  :key="index"
                  :class="[{ 'drag-resize': rect.mode === 'chart' || rect.mode === 'table' },
                  {'drag-chart': rect.mode === 'chart'},
                  {'drag-table': rect.mode === 'table'},
                  ]"
                  :w="rect.width"
                  :h="rect.height"
                  :x="rect.left"
                  :y="rect.top"
                  :parent-w="plwidth"
                  :parent-h="plheight"
                  :axis="rect.axis"
                  :is-active="rect.active && editable"
                  :minw="rect.minw"
                  :minh="rect.minh"
                  :is-draggable="rect.draggable && editable"
                  :is-resizable="rect.resizable && editable"
                  :parent-limitation="rect.parentLim"
                  :snap-to-grid="rect.snapToGrid"
                  :aspect-ratio="rect.aspectRatio"
                  :z="rect.zIndex"
                  :content-class="rect.class"
                  @activated="addactive(index)"
                  @deactivated="activethrow(index)"
                  @dragging="changePosition($event, index)"
                  @resizing="changeSize($event, index)"
                  @contextmenu.native.prevent="showOptions(rect)"
                >
                  <table-view
                    v-if="rect.mode==='table'"
                    :index="1"
                    :table="rect.tableData"
                    :can-edit="false"
                    :show-table-title="true"
                    :vcentred="rect.vcentred"
                  />
                  <table-chart
                    v-else-if="rect.mode==='chart' && rect.tableData.data"
                    :right-admin="false"
                    :print-mode="true"
                    :table-data="rect.tableData"
                    :hard-assignments-chart="parseInt(rect.chartID, 10)"
                    :inn-height="rect.height"
                  />
                  <div
                    v-else-if="rect.mode==='text'"
                    style="width: 100%; height: 100%; white-space: pre-wrap; display: flex;"
                    :style="textStyle(rect)"
                    @dblclick="editText(rect)"
                    v-text="rect.text"
                  />
                  <div
                    v-else-if="rect.mode==='pagenumb'"
                    style="width: 100%; height: 100%; white-space: pre-wrap;"
                    :style="textStyle(rect)"
                    @dblclick="editText(rect)"
                    v-text="selectedPage.pagenumber+1"
                  />
                </vue-drag-resize>
              </div>
            </div>
            <div
              v-if="selectedPage && !fullscreen"
              class="present-page-title"
            >
              {{ selectedPage.pagetitle }}
            </div>
          </div>
          <div class="action-side-bar">
            <div class="action-side-bar-inner">
              <template v-for="(btn, indx) in barBtn">
                <v-tooltip
                  :key="'btn-' + indx"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      fab
                      dark
                      small
                      :color="btn.color"
                      v-bind="attrs"
                      :disabled="(!haveChanges && btn.icon === 'mdi-content-save') || (!reportHaveChart && btn.icon === 'mdi-chart-box-plus-outline')"
                      @click="btn.action"
                      v-on="on"
                    >
                      <v-icon>
                        {{ btn.icon }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ btn.text }}</span>
                </v-tooltip>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <text-editor-dialog
      v-if="rectInput && editTextDialog"
      v-model="editTextDialog"
      :rect-input="rectInput"
      @updateRect="rectInput = $event"
    />
    <v-dialog
      v-model="addTableDialog"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          Добавление {{ rectMode === 'table' ? 'таблицы' : 'графика' }}
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="templateId"
            item-value="id"
            item-text="title"
            :items="tablesTemplatesList"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="addTableDialog = false"
          >
            отмена
          </v-btn>
          <v-spacer />
          <v-btn
            @click="getTableTemplate()"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="addChartDialog"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          Добавление графика
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="templateChart"
            :items="chartTemplatesList"
            item-text="title"
            return-object
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="addChartDialog = false"
          >
            отмена
          </v-btn>
          <v-spacer />
          <v-btn
            @click="getChartTemplate()"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="tableConfigDialog"
      max-width="600"
      :persistent=true
    >
      <v-card v-if="tableConfig">
        <v-card-text>
          <v-text-field
            v-if="tableConfig.mode === 'table'"
            v-model="tableConfig.maxRows"
            label="Максимальное количество строк"
            type="number"
            min="1"
            max="100"
          />
          <v-text-field
            v-if="tableConfig.mode === 'table'"
            v-model="tableConfig.maxRowsTransfer"
            label="Оптимальное количество строк до переноса"
            type="number"
            min="1"
            max="100"
          />
          <v-text-field
            v-if="tableConfig.mode === 'table'"
            v-model="tableConfig.maxTables"
            label="Максимальное количество динамических таблиц"
            type="number"
            min="1"
            max="100"
          />
          <v-checkbox
            v-model="tableConfig.vcentred"
            label="Выровнять таблицу вертикально внутри области"
          />
          <v-checkbox
            v-model="tableConfig.showTableTitle"
            label="Показывать название таблицы"
          />
          <v-select
            :value="tableConfig.tableData.info.id"
            :items="tablesTemplatesList"
            item-text="title"
            item-value="id"
            @change="changeUsedTable"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="cancelChange"
          >
            отмена
          </v-btn>
          <v-spacer />
          <v-btn
            @click="saveChange"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="fillDataDialog"
      max-width="650"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <v-select
                v-model="plperiodid"
                label="Период"
                :items="plPeriods"
              />
              <v-select
                v-model="plversionid"
                label="Версия"
                :disabled="!plperiodid"
                item-value="plversionid"
                item-text="title"
                :items="plperiodid ? plVersions[plperiodid] : []"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="!(plperiodid && plversionid)"
            color="primary"
            @click="fillAllTables([plreportid, plversionid])"
          >
            Получить данные
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <choose-present
      :main-dialog="mainDialog"
      @getPrPages="getPrPages"
      @chancel="cancelChoose"
    />
    <v-dialog
      v-model="pageDialog"
      width="450"
    >
      <v-card
        v-if="tempPage"
      >
        <v-card-text>
          <v-text-field
            v-model="tempPage.pagetitle"
            label="Заголовок страницы"
          />
          <v-checkbox
            v-if="tempPage.pageid"
            v-model="makeCopy"
            label="сделать копию"
          />
          <v-checkbox
            v-model="tempPage.flgrightsadvanced"
            label="Видимость только для rightsadvanced"
          />
          <v-img
            v-if="tempPage.template && tempPage.template.backgroundImage"
            :src="tempPage.template.backgroundImage"
            max-height="150"
            max-width="250"
            aspect-ratio="1.7"
            contain
          />
          <v-file-input
            truncate-length="15"
            label="Фон страницы"
            @change="uploadImage($event)"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="ma-0"
            color="primary"
            @click="addPage(tempPage.pagenumber)"
          >
            {{ !tempPage.pageid || makeCopy ? 'добавить' : 'сохранить' }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="warning"
            @click="[pageDialog=false, tempPage=null]"
          >
            отмена
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <data-loading />
    <v-overlay
      v-if="showOverlay"
      absolute
      color="black"
      opacity="0.9"
      class="sss"
    >
      <v-btn
        absolute
        right
        bottom
        color="amber lighten-5"
        class="teal--text text--darken-4 font-weight-bold"
        @click="[mainDialog=true, showOverlay=false]"
      >..выбрать презентацию</v-btn>
    </v-overlay>
    <div
      class="menu-settings"
    >
      <div
        class="button"
        @click="menu = true"
      />
      <v-menu
        class="menu"
        v-model="menu"
        :close-on-content-click="false"
        :attach="true"
      >
        <v-card
          class="card-setting"
          min-width="300"
        >
          <v-list>
            <v-list-item>
              <v-switch
                v-model="gridActive"
                color="green"
                label="Включить сетку"
                hide-details
              ></v-switch>
            </v-list-item>
          </v-list>
            <v-card-text v-if="gridActive">
              <v-slider
                v-model="valueGrid"
                step="1"
                thumb-label
                max="10"
                min="1"
                :persistent-hint="true"
              ></v-slider>
            </v-card-text>
        </v-card>
      </v-menu>
    </div>
  </span>
</template>

<script>
  import tableView from './tableVfViewLite'
  import tableChart from './tableChart'
  import VueDragResize from '../components/core/DragResize/vue-drag-resize.vue'
  import { api as fullscreen } from 'vue-fullscreen'
  import dataLoading from '../components/dataLoading'
  import choosePresent from './components/choosePresntationDialog'
  import textEditorDialog from './components/textEditorDialog'
  import draggable from 'vuedraggable'

  export default {
    name: 'ReportEditor',
    components: {
      VueDragResize,
      tableView,
      tableChart,
      dataLoading,
      choosePresent,
      textEditorDialog,
      draggable,
    },
    data () {
      return {
        fileDU: null,
        file: null,
        makeCopy: false,
        editable: true,
        mainDialog: true,
        pageDialog: false,
        editTextDialog: false,
        fillDataDialog: false,
        tableConfigDialog: false,
        addTableDialog: false,
        addChartDialog: false,
        tempPage: null,
        showOverlay: false,
        plversionid: null,
        plperiodid: null,
        plPeriods: null,
        plVersions: null,
        prTemplate: null,
        planningTables: {},
        versions: {},
        planningReportsGets: [],
        barBtn: [
          {
            icon: 'mdi-overscan',
            action: () => this.slideMode(1),
            text: 'Режим презентации',
            color: 'primary',
          },
          {
            icon: 'mdi-content-save',
            action: () => this.saveReportPattern(),
            text: 'Сохранить шаблон',
            color: 'warning',
          },
          {
            icon: 'mdi-chart-box-plus-outline',
            action: () => this.addChartToPage(),
            text: 'Добавить график',
            color: 'primary',
          },
          {
            icon: 'mdi-table-plus',
            action: () => this.addTableToPage(),
            text: 'Добавить таблицу',
            color: 'primary',
          },
          {
            icon: 'mdi-text-box-plus-outline',
            action: () => this.addTextToPage(),
            text: 'Добавить текст',
            color: 'primary',
          },
          {
            icon: 'mdi-numeric-1-box-multiple-outline',
            action: () => this.addPNumbToPage(),
            text: 'Добавить номер страницы',
            color: 'primary',
          },
          {
            icon: 'mdi-plus',
            action: () => this.zoomIn(),
            text: 'Увеличить масштаб',
            color: 'primary',
          },
          {
            icon: 'mdi-minus',
            action: () => this.zoomOut(),
            text: 'Уменьшить масштаб',
            color: 'primary',
          },
          // {
          //   icon: 'mdi-palette-outline',
          //   action: () => { this.pStylesDialog = true },
          //   text: 'Стиль презентации',
          //   color: 'primary',
          // },
          {
            icon: 'mdi-database-arrow-down-outline',
            action: () => { this.fillDataDialog = true },
            text: 'Заполнить  данные',
            color: 'primary',
          },
          // {
          //   icon: 'mdi-bat',
          //   action: () => this.changeRectsStyle(),
          //   text: 'обновить стили',
          //   color: 'primary',
          // },
          {
            icon: 'mdi-subdirectory-arrow-left',
            action: () => this.choiceReport(),
            text: 'К выбору отчетов',
            color: 'primary',
          },
        ],
        tableConfig: null,
        tableConfigCopy: null,
        plreportid: null,
        activeArr: [],
        templateId: null,
        templateChart: null,
        rectMode: 'table',
        pages: [],
        pStylesDialog: false,
        selectedPage: null,
        initPages: null,
        plwidth: 1754,
        plheight: 1240,
        listWidth: 0,
        listHeight: 0,
        windowHeight: 0,
        dragged: false,
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        mouseOffsetX: 0,
        mouseOffsetY: 0,
        tableData: null,
        scaleVolPrint: false,
        fullscreen: false,
        scale: 1,
        zoomStep: 0.03,
        rectInput: null,
        tablesTemplatesList: null,
        versionTables: null,
        pagesToDelete: [],
        plpresentation: null,
        menu: false,
        gridActive: false,
        valueGrid: 4,
        activeSlide: false,
      }
    },
    computed: {
      // eslint-disable-next-line vue/return-in-computed-property
      haveChanges () {
        return JSON.stringify(this.pages) !== JSON.stringify(this.initPages)
      },
      reportHaveChart () {
        if (!this.tablesTemplatesList) return false
        let haveChart = false
        const charts = this.tablesTemplatesList.filter(x => !!x.chpattern)
        if (charts.length > 0) {
          haveChart = true
        }
        return haveChart
      },
      chartTemplatesList () {
        if (this.tablesTemplatesList) {
          const allPatterns = []
          const chpatterns = this.tablesTemplatesList.filter(x => !!x.chpattern)
          chpatterns.forEach((pattern) => {
            const pJSON = JSON.parse('{' + pattern.chpattern + '}')
            for (const chID in pJSON) {
              const chTitle = pJSON[chID].title || chID
              allPatterns.push({ chartID: chID, title: pattern.title + ' (' + chTitle + ')', tableID: pattern.id })
            }
          })
          return allPatterns
        } else {
          return []
        }
      },
      createNewPrTp () {
        return this.templateSelected && this.selectedArea && this.chosenPlTemplate && this.templateSelected.length === 0
      },
      dataLoading () {
        return this.$store.getters.dataLoading
      },
      scaleVol () {
        if (this.scaleVolPrint) {
          return 1
        } else {
          return this.scale
        }
      },
      tableStyles () {
        return this.$store.getters.tableStyles
      },
    },
    watch: {
      fullscreen () {
        this.onResize()
      },
      gridActive () {
        this.buildGrid()
      },
      valueGrid () {
        this.buildGrid()
      },
      selectedPage: {
        handler () {
          this.activeSlide = true
        },
        deep: true,
      },
    },
    mounted () {
      if (this.tableStyles === null) {
        this.$store.dispatch('getTableStyles')
      }
      this.windowHeight = window.innerHeight
      this.$nextTick(() => {
        this.onResize()
        window.addEventListener('resize', this.onResize)
        window.addEventListener('keyup', this.myMethod)
      })
    },
    methods: {
      buildGrid () {
        if (!this.$refs.canvasGrid) {
          return
        }
        const correctionValueGrid = this.valueGrid * 10
        const context = this.$refs.canvasGrid.getContext('2d')
        context.clearRect(0, 0, this.plwidth, this.plheight)
        context.beginPath()
        if (!this.gridActive) {
          return
        }
        this.$refs.canvasGrid.width = this.plwidth
        this.$refs.canvasGrid.height = this.plheight
        const stepWidth = this.plwidth / correctionValueGrid
        for (let x = 1; x < correctionValueGrid; x++) {
          context.moveTo(stepWidth * x, 0)
          context.lineTo(stepWidth * x, this.plheight)
        }
        for (let y = 1; y < correctionValueGrid; y++) {
          context.moveTo(0, stepWidth * y)
          context.lineTo(this.plwidth, stepWidth * y)
        }
        context.strokeStyle = 'rgb(161, 150, 150, 0.5)'
        context.stroke()
      },
      dragChange () {
        this.pages.forEach((item, index) => {
          item.pagenumber = index
        })
      },
      saveChange () {
        this.tableConfigDialog = false
      },
      cancelChange () {
        Object.assign(this.tableConfig, this.tableConfigCopy)
        this.tableConfigDialog = false
      },

      changeRectsStyle () {
        this.pages.forEach((page, index) => {
          if (index > 0 && !page.pagetitle.includes('Раздел')) {
            console.log('bat', page)
            if (page.pagepattern.data[1] && page.pagepattern.data[1].mode === 'text') {
              const pp = page.pagepattern.data[1]
              pp.height = 87
              pp.left = 441
              pp.top = 14
              pp.width = 1313
              pp.textStyle.alignItems = 'flex-end'
              pp.textStyle.fontFamily = 'Verdana, sans-serif'
              pp.textStyle.fontStyle = 'normal'
              pp.textStyle.fontSize = 45
              pp.textStyle.justifyContent = 'center'
              pp.textStyle.color = null
              pp.textStyle.paddingRight = 0
              page.pagepattern.data[1] = pp
            }
            if (page.pagepattern.data[2] && page.pagepattern.data[2].mode === 'table') {
              const pp = page.pagepattern.data[2]
              pp.height = 988
              pp.left = 80
              pp.top = 195
              pp.width = 1596
              page.pagepattern.data[2] = pp
            }
            if (page.pagepattern.data[2] && page.pagepattern.data[2].mode === 'chart') {
              const pp = page.pagepattern.data[2]
              pp.height = 908
              pp.left = 317
              pp.top = 214
              pp.width = 1378
              page.pagepattern.data[2] = pp
            }
          }
          // if (index > 0 && page.pagetitle.includes('Раздел')) {
          //   console.log('bat', page)
          //   if (page.pagepattern.data[1] && page.pagepattern.data[1].mode === 'text') {
          //     const pp = page.pagepattern.data[1]
          //     pp.textStyle.color = {
          //       alpha: 1,
          //       hex: '#03044D',
          //       hexa: '#03044DFF',
          //       hsla:
          //         {
          //           h: 239.1891891891892,
          //           s: 0.9249999999999999,
          //           l: 0.1568627450980392,
          //           a: 1,
          //         },
          //       hsva:
          //         {
          //           h: 239.1891891891892,
          //           s: 0.961038961038961,
          //           v: 0.30196078431372547,
          //           a: 1,
          //         },
          //       hue: 239.1891891891892,
          //       rgba:
          //         {
          //           r: 3,
          //           g: 4,
          //           b: 77,
          //           a: 1,
          //         },
          //     }
          //     pp.height = 113
          //     pp.left = 93
          //     pp.top = 407
          //     pp.width = 1564
          //     page.pagepattern.data[1] = pp
          //   }
          //   if (page.pagepattern.data[2] && page.pagepattern.data[2].mode === 'text') {
          //     const pp = page.pagepattern.data[2]
          //     pp.textStyle.color = {
          //       alpha: 1,
          //       hex: '#03044D',
          //       hexa: '#03044DFF',
          //       hsla:
          //         {
          //           h: 239.1891891891892,
          //           s: 0.9249999999999999,
          //           l: 0.1568627450980392,
          //           a: 1,
          //         },
          //       hsva:
          //         {
          //           h: 239.1891891891892,
          //           s: 0.961038961038961,
          //           v: 0.30196078431372547,
          //           a: 1,
          //         },
          //       hue: 239.1891891891892,
          //       rgba:
          //         {
          //           r: 3,
          //           g: 4,
          //           b: 77,
          //           a: 1,
          //         },
          //     }
          //     pp.textStyle.color = {
          //       alpha: 1,
          //       hex: '#E10010',
          //       hexa: '#E10010FF',
          //       hsla:
          //         {
          //           h: 355.73333333333335,
          //           s: 1,
          //           l: 0.4411764705882353,
          //           a: 1,
          //         },
          //       hsva:
          //         {
          //           h: 355.73333333333335,
          //           s: 1,
          //           v: 0.8823529411764706,
          //           a: 1,
          //         },
          //       hue: 355.73333333333335,
          //       rgba:
          //         {
          //           r: 225,
          //           g: 0,
          //           b: 16,
          //           a: 1,
          //         },
          //     }
          //     pp.height = 184
          //     pp.left = 95
          //     pp.top = 533
          //     pp.width = 1563
          //     page.pagepattern.data[2] = pp
          //   }
          // }
        })
      },
      changeUsedTable (evnt) {
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('getTableTemplate', evnt).then((res) => {
          this.tableConfig.tableData = res
          this.$store.dispatch('setDataLoading', false)
        }).catch((e) => {
          console.error('Error: ', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      pageStyle (page) {
        if (page && page.template && page.template.backgroundImage) {
          return `background-image: url('${page.template.backgroundImage}'); background-size: cover;`
        } else if (this.plpresentation.globaltemplate && this.plpresentation.globaltemplate.backgroundImage) {
          return `background-image: url('${this.plpresentation.globaltemplate.backgroundImage}'); background-size: cover;`
        }
        return ''
      },
      getBase64 (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = function () {
            resolve(reader.result)
          }
          reader.onerror = function (error) {
            reject(error)
          }
        })
      },
      uploadImage (event) {
        this.getBase64(event).then((resp) => {
          if (this.tempPage.template) {
            this.tempPage.template.backgroundImage = resp
          } else {
            this.tempPage.template = {}
            this.tempPage.template.backgroundImage = resp
          }
        })
      },
      showPageDialog (pageIndex = null) {
        if (pageIndex === null) {
          this.tempPage = {
            pageid: null,
            plprid: this.prTemplate,
            pagetitle: 'page-' + (this.pages.length + 1),
            pagenumber: this.pages.length,
            pagepattern: {
              appearance: {},
              data: [],
            },
            template: null,
          }
        } else {
          this.tempPage = JSON.parse(JSON.stringify(this.pages[pageIndex]))
        }
        this.pageDialog = true
        // this.makeCopy = false
      },
      addPage (pagenumb) {
        if (this.tempPage.pageid === null || this.makeCopy) {
          this.tempPage.pageid = null
          this.tempPage.pagenumber = this.pages.length
          this.pages.push(this.tempPage)
          pagenumb = this.pages.length - 1
        } else {
          const pageIndex = this.pages.findIndex(x => x.pageid === this.tempPage.pageid)
          this.$set(this.pages[pageIndex], 'modified', 'true')
          Object.assign(this.pages[pageIndex], this.tempPage)
        }
        this.choosePage(pagenumb)
        this.pageDialog = false
      },
      getPrPages (payload) {
        this.mainDialog = false
        this.$store.dispatch('setDataLoading', true)
        this.plpresentation = payload.present
        if (this.plpresentation?.globaltemplate) {
          this.plpresentation.globaltemplate = JSON.parse(this.plpresentation.globaltemplate.replace(/'/g, '"'))
        }
        this.plreportid = payload.cr
        // this.$store.dispatch('getPlanningVersionsList', { chosenRep: payload.cr, areaid: payload.area }).then((resp) => {
        //   this.plVersions = resp
        //   this.plPeriods = Object.keys(resp)
        // })
        this.$store.dispatch('getPrData', payload.plt).then((prdata) => {
          this.$store.dispatch('getTablesTempsList', payload.cr).then((resp) => {
            this.tablesTemplatesList = resp
            this.prTemplate = payload.plt
            prdata.forEach((page) => {
              page.pagepattern = JSON.parse(page.pagepattern.replace(/'/g, '"'))
              if (page.template) {
                page.template = JSON.parse(page.template.replace(/'/g, '"'))
              }
            })
            this.pages = prdata
            this.initPages = JSON.parse(JSON.stringify(this.pages))
            this.$store.dispatch('setDataLoading', false)
          }).catch((e) => {
            console.error('Error:', e)
            this.$store.dispatch('setDataLoading', false)
            this.mainDialog = true
          })
        }).catch((e) => {
          this.$store.dispatch('setDataLoading', false)
          this.mainDialog = true
        })
      },
      deletePage (indx) {
        this.$store.dispatch('setDataLoading', true)
        if (this.selectedPage && this.selectedPage.pagenumber === indx) {
          this.selectedPage = null
        }
        this.$store.dispatch('delPresentationPage', { pageid: this.pages[indx].pageid }).then((resp) => {
          console.log('del resp', resp)
          this.pages.splice(indx, 1)
          this.pages.forEach((p, i) => {
            if (i >= indx) {
              p.pagenumber = i
            }
          })
          this.$store.dispatch('setDataLoading', false)
        }).catch((e) => {
          console.error('Error:', e)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      trimRows (maxRows, tableData) {
        const table = JSON.parse(JSON.stringify(tableData))
        table.data = tableData.data.splice(0, maxRows)
        return table
      },
      showOptions (rect) {
        if (rect.mode === 'table') {
          this.tableConfigDialog = true
          if (this.tableConfig) {
            return
          }
          this.tableConfig = rect
          this.tableConfigCopy = JSON.parse(JSON.stringify(rect))
        }
      },
      fillAllTables ([plreportid, plversionid]) {
        this.fillDataDialog = false
        this.$store.dispatch('setDataLoading', true)
        let versionTables = null
        this.$store.dispatch('getRowPlanningDataList', { plreportid: plreportid, plversionid: plversionid }).then(resp => {
          versionTables = resp
          this.pages.forEach((page) => {
            page.pagepattern.data.forEach((rect) => {
              if ((rect.mode === 'table' || rect.mode === 'chart') && rect.tableData && rect.tableData.info && rect.tableData.info.id && versionTables) {
                const temptableList = versionTables.filter(x => x.pltableid === rect.tableData.info.id) || { id: null }
                temptableList.forEach((temptable) => {
                  if (temptable.id) {
                    this.$store.dispatch('getTableData', temptable.id).then((resp) => {
                      if (rect.maxRows) {
                        const tableData = []
                        rect.tableData = resp
                        while (rect.tableData.data.length >= rect.maxRows) {
                          const trimmed = this.trimRows(rect.maxRows, rect.tableData)
                          tableData.push(trimmed)
                        }
                        tableData.forEach((ttable, index) => {
                          if (index === 0) {
                            rect.tableData.data = ttable.data
                            rect.tableData.info.title = resp.info.title
                          } else {
                            const newPage = {
                              pagenumber: page.pagenumber + '-' + (index),
                              pagetitle: page.pagetitle + '-' + (index),
                              // slideBG: '/img/alfacon-bg.cc353f84.png',
                              pagepattern: {
                                data: [
                                  JSON.parse(
                                    JSON.stringify(rect)
                                  ),
                                ],
                              },
                            }
                            rect.tableData.info.title = resp.info.title
                            newPage.pagepattern.data[0].tableData = ttable
                            this.pages.splice(page.pagenumber + index, 0, newPage)
                          }
                        })
                      } else {
                        rect.tableData.info.title = resp.info.title
                        rect.tableData.data = resp.data
                        rect.tableData.col = resp.col
                        rect.tableData.row = resp.row
                      }
                    })
                  } else {
                    console.log('aaaa', temptable)
                  }
                })
              }
            })
          })
          setTimeout(() => this.$store.dispatch('setDataLoading', false), 800)
        }).catch((e) => {
          this.$store.dispatch('setDataLoading', false)
        })
      },
      zoomIn () {
        this.scale = this.scale + this.zoomStep
      },
      zoomOut () {
        this.scale = this.scale - this.zoomStep
      },
      choiceReport () {
        this.mainDialog = true
      },
      cancelChoose () {
        this.mainDialog = false
        if (this.plreportid) {
          this.showOverlay = false
          return
        }
        this.showOverlay = true
      },
      myMethod (evnt) {
        if (evnt.code === 'Backspace' && this.activeArr.length > 0) {
          if (this.activeArr.length > 1) {
            this.activeArr.sort((a, b) => {
              return b - a
            })
            this.activeArr.forEach((x) => {
              this.selectedPage.pagepattern.data.splice(x, 1)
            })
          } else {
            this.selectedPage.pagepattern.data.splice(this.activeArr[0], 1)
          }
          this.activeArr = []
        }
      },
      activethrow (index) {
        const activeIndex = this.activeArr.findIndex(x => x === index)
        this.activeArr.splice(activeIndex, 1)
      },
      addactive (index) {
        this.activeArr.push(index)
      },
      getTableTemplate () {
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('getTableTemplate', this.templateId).then((res) => {
          this.selectedPage.pagepattern.data.push({
            maxTables: null,
            maxRows: null,
            width: 200,
            height: 150,
            top: 150,
            left: 150,
            draggable: true,
            resizable: true,
            minw: 10,
            minh: 10,
            axis: 'both',
            parentLim: true,
            snapToGrid: false,
            aspectRatio: false,
            zIndex: 1,
            color: '#EF9A9A',
            active: false,
            mode: this.rectMode,
            fontSize: 35,
            tableData: res,
          })
          this.$store.dispatch('setDataLoading', false)
          this.addTableDialog = false
        }).catch((e) => {
          console.error('Error: ', e)
          this.selectedPage.modified = true
          this.$store.dispatch('setDataLoading', false)
          this.addTableDialog = false
        })
      },
      getChartTemplate () {
        this.$store.dispatch('setDataLoading', true)
        this.$store.dispatch('getTableTemplate', this.templateChart.tableID).then((res) => {
          this.selectedPage.pagepattern.data.push({
            maxRows: null,
            width: 200,
            height: 150,
            top: 150,
            left: 150,
            draggable: true,
            resizable: true,
            minw: 10,
            minh: 10,
            axis: 'both',
            parentLim: true,
            snapToGrid: false,
            aspectRatio: false,
            zIndex: 1,
            color: '#EF9A9A',
            active: false,
            mode: this.rectMode,
            fontSize: 35,
            tableData: res,
            chartID: this.templateChart.chartID,
          })
          this.$store.dispatch('setDataLoading', false)
          this.addChartDialog = false
        }).catch((e) => {
          console.error('Error: ', e)
          this.selectedPage.modified = true
          this.$store.dispatch('setDataLoading', false)
          this.addChartDialog = false
        })
      },
      textStyle (rect) {
        let addStyle = ''
        if (rect.textStyle) {
          for (const key in rect.textStyle) {
            if (rect.textStyle[key]) {
              if (['color', 'backgroundColor'].includes(key)) {
                addStyle = `${addStyle} ${key.replace(/[A-Z]/g, '-$&').toLowerCase()}: ${rect.textStyle[key].hex};`
              } else if (key == 'borderColor') {
                addStyle = `${addStyle} border: 3px solid ${rect.textStyle[key].hex};`
              } else if (Number.isInteger(rect.textStyle[key])) {
                addStyle = `${addStyle} ${key.replace(/[A-Z]/g, '-$&').toLowerCase()}: ${rect.textStyle[key]}px;`
              } else {
                addStyle = `${addStyle} ${key.replace(/[A-Z]/g, '-$&').toLowerCase()}: ${rect.textStyle[key]};`
              }
            }
          }
        }
        return addStyle
      },
      editText (rect) {
        this.rectInput = {}
        this.rectInput = rect
        const editedKeys = [
          'fontSize',
          'fontFamily',
          'alignItems',
          'justifyContent',
          'backgroundColor',
          'paddingLeft',
          'paddingTop',
          'paddingBottom',
          'paddingRight',
          'textAlign',
          'color',
          'fontStyle',
        ]
        if (!this.rectInput.textStyle) {
          this.rectInput.textStyle = {}
        }
        editedKeys.forEach((k) => {
          if (!this.rectInput.textStyle[k]) {
            this.rectInput.textStyle[k] = null
          }
        })
        this.editTextDialog = true
      },
      choosePage (pageNumb) {
        this.selectedPage = null
        this.$nextTick(() => {
          this.selectedPage = this.pages[pageNumb]
        })
        this.tableConfig = null
        setTimeout(() => {
          this.buildGrid()
        }, 0)
      },
      addPNumbToPage () {
        this.selectedPage.modified = true
        this.selectedPage.pagepattern.data.push({
          maxRows: null,
          width: 52,
          height: 61,
          top: 1179,
          left: 1702,
          draggable: true,
          resizable: true,
          minw: 10,
          minh: 10,
          axis: 'both',
          parentLim: true,
          snapToGrid: false,
          aspectRatio: false,
          zIndex: 1,
          color: '#EF9A9A',
          active: false,
          mode: 'pagenumb',
          textStyle:
            {
              fontSize: 22,
              fontFamily: null,
              alignItems: null,
              justifyContent: null,
              backgroundColor: null,
              paddingLeft: null,
              paddingTop: null,
              paddingBottom: null,
              paddingRight: null,
              textAlign: null,
              color: null,
              fontStyle: null,
            },
        })
      // todo добавление текста на страницу
      },
      addTextToPage () {
        this.selectedPage.modified = true
        this.selectedPage.pagepattern.data.push({
          maxRows: null,
          width: 200,
          height: 150,
          top: 150,
          left: 150,
          draggable: true,
          resizable: true,
          minw: 10,
          minh: 10,
          axis: 'both',
          parentLim: true,
          snapToGrid: false,
          aspectRatio: false,
          zIndex: 1,
          color: '#EF9A9A',
          active: false,
          mode: 'text',
          text: 'Введите текст...',
          textStyle: {},
        })
      // todo добавление текста на страницу
      },
      addChartToPage () {
        this.selectedPage.modified = true
        this.rectMode = 'chart'
        this.addChartDialog = true
      },
      addTableToPage () {
        this.selectedPage.modified = true
        this.rectMode = 'table'
        this.addTableDialog = true
      },
      saveReportPattern () {
        const modifiedPages = this.pages.filter((page, index) => {
          return !!page.modified || JSON.stringify(this.initPages[index]) !== JSON.stringify(page)
        })
        if (modifiedPages.length > 0) {
          this.$store.dispatch('setDataLoading', true)
          modifiedPages.forEach((page) => {
            page.pagepattern.data.forEach((items) => {
              if (items.tableData) {
                items.tableData.data = []
              }
            })
            page.flgrightsadvanced = page.flgrightsadvanced || false
            this.$store.dispatch('updatePrPageData', JSON.parse(JSON.stringify(page))).then((resp) => {
              page.pageid = resp.prespageid
              page.modified = false
              this.initPages = JSON.parse(JSON.stringify(this.pages))
              this.$store.dispatch('setDataLoading', false)
            }).catch((e) => {
              console.error('error', e)
              this.$store.dispatch('setDataLoading', false)
            })
          })
        }
      },
      flipPage (direction) {
        if (!this.selectedPage) {
          this.selectedPage = this.pages.find(x => x.pagenumber === 0)
        } else {
          if (direction < 0 && this.selectedPage.pagenumber === 0) return null
          if (direction > 0 && this.selectedPage.pagenumber === this.pages.length - 1) return null
          this.selectedPage = this.pages.find(x => x.pagenumber === this.selectedPage.pagenumber + direction)
        }
        return null
      },
      slideMode (page) {
        document.addEventListener('keyup', (evnt) => {
          console.log(evnt)
          let direct = 0
          if (evnt.key === 'ArrowLeft' || evnt.key === 'ArrowDown') {
            direct = -1
          } else if (evnt.key === 'ArrowRight' || evnt.key === 'ArrowUp') {
            direct = 1
          }
          if (direct !== 0) this.flipPage(direct)
        })
        fullscreen.toggle(this.$el.querySelector('#page-layout'), {
          teleport: false,
          callback: (isFullscreen) => {
            this.editable = !isFullscreen
            this.fullscreen = isFullscreen
          },
        })
      },
      changePosition (newRect, index) {
        this.selectedPage.pagepattern.data[index].top = newRect.top
        this.selectedPage.pagepattern.data[index].left = newRect.left
        this.selectedPage.pagepattern.data[index].width = newRect.width
        this.selectedPage.pagepattern.data[index].height = newRect.height
      },
      changeSize (newRect, index) {
        this.selectedPage.pagepattern.data[index].top = newRect.top
        this.selectedPage.pagepattern.data[index].left = newRect.left
        this.selectedPage.pagepattern.data[index].width = newRect.width
        this.selectedPage.pagepattern.data[index].height = newRect.height
      },
      resize (newRect) {
        this.width = newRect.width
        this.height = newRect.height
        this.top = newRect.top
        this.left = newRect.left
      },
      startDrag (evt) {
        this.dragged = true
        this.mouseOffsetX = evt.offsetX
        this.mouseOffsetY = evt.offsetY
      },
      onResize () {
        this.windowHeight = window.innerHeight
        const addMinus = this.fullscreen ? 40 : 75 + 40
        this.scale = (this.windowHeight - addMinus) / this.plheight * 100 / 100
      },
    },
  }
</script>

<style lang="scss">
@import '../../../assets/dx.llight.css';

#gridContainer {
  height: 440px;
}

.drag-resize {
  border: 1px dashed grey;
}
.drag-table {
  &::after {
    content: 'Таблица';
    position: absolute;
    bottom: -20px;
    right: 0;
  }
}
.drag-chart {
  &::after {
    content: 'График';
    position: absolute;
    bottom: -20px;
    right: 0;
  }
}

.card-setting {
  margin: 0;
}

.canvas-grid {
  position: absolute;
  top: 0;
  left: 0;
}

.menu-settings {
  position: absolute;
  top: 15px;
  right: 90px;
  .v-menu__content {
    right: 0;
    left: auto !important; // переопределяем стили vuetify для расположения меню настройки
    top: auto !important;
  }
  .v-input--selection-controls {
    margin: 0;
    padding: 0;
  }
  .button {
    width: 50px;
    height: 50px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0,0,256,256' width='35px' height='35px' fill-rule='nonzero'%3E%3Cg fill='%23c0c0c0' fill-rule='nonzero' stroke='none' stroke-width='1' stroke-linecap='butt' stroke-linejoin='miter' stroke-miterlimit='10' stroke-dasharray='' stroke-dashoffset='0' font-family='none' font-weight='none' font-size='none' text-anchor='none' style='mix-blend-mode: normal'%3E%3Cg transform='scale(5.12,5.12)'%3E%3Cpath d='M47.16,21.221l-5.91,-0.966c-0.346,-1.186 -0.819,-2.326 -1.411,-3.405l3.45,-4.917c0.279,-0.397 0.231,-0.938 -0.112,-1.282l-3.889,-3.887c-0.347,-0.346 -0.893,-0.391 -1.291,-0.104l-4.843,3.481c-1.089,-0.602 -2.239,-1.08 -3.432,-1.427l-1.031,-5.886c-0.084,-0.478 -0.499,-0.828 -0.985,-0.828h-5.5c-0.49,0 -0.908,0.355 -0.987,0.839l-0.956,5.854c-1.2,0.345 -2.352,0.818 -3.437,1.412l-4.83,-3.45c-0.399,-0.285 -0.942,-0.239 -1.289,0.106l-3.887,3.887c-0.343,0.343 -0.391,0.883 -0.112,1.28l3.399,4.863c-0.605,1.095 -1.087,2.254 -1.438,3.46l-5.831,0.971c-0.482,0.08 -0.836,0.498 -0.836,0.986v5.5c0,0.485 0.348,0.9 0.825,0.985l5.831,1.034c0.349,1.203 0.831,2.362 1.438,3.46l-3.441,4.813c-0.284,0.397 -0.239,0.942 0.106,1.289l3.888,3.891c0.343,0.343 0.884,0.391 1.281,0.112l4.87,-3.411c1.093,0.601 2.248,1.078 3.445,1.424l0.976,5.861c0.079,0.481 0.496,0.834 0.985,0.834h5.5c0.485,0 0.9,-0.348 0.984,-0.825l1.045,-5.89c1.199,-0.353 2.348,-0.833 3.43,-1.435l4.905,3.441c0.398,0.281 0.938,0.232 1.282,-0.111l3.888,-3.891c0.346,-0.347 0.391,-0.894 0.104,-1.292l-3.498,-4.857c0.593,-1.08 1.064,-2.222 1.407,-3.408l5.918,-1.039c0.479,-0.084 0.827,-0.5 0.827,-0.985v-5.5c0.001,-0.49 -0.354,-0.908 -0.838,-0.987zM25,32c-3.866,0 -7,-3.134 -7,-7c0,-3.866 3.134,-7 7,-7c3.866,0 7,3.134 7,7c0,3.866 -3.134,7 -7,7z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    cursor: pointer;
  }
}

.sss > .v-overlay__content {
  width: 100%;
  height: 100%;
}
td {
  padding: 0px!important;
}
.v-tabs .v-tabs-bar .v-tab.v-tab--active.active-tab {
  color: #3c4858;
}

.version-label {
  position: absolute;
  z-index: 1010;
  right: 20px;
  top: -12px;
  height: 25px;
  border-radius: 10px;
  background-color: #e5e5e5;
}
.page-layout {
  /*margin: 20px;*/
  left: 26px;
  overflow: hidden;
  height: 100%;
  position: absolute;
  transform-origin: 0 0;
  box-shadow: 0 2px 5px #3c4858;
  background-color: #ffffff;
}
.present-page-title {
  position: absolute;
  left: 10px;
  top: 6px;
  color: #3a0909;
  background-color: rgba(229, 229, 229, 0.72);
  padding: 6px;
  border-radius: 6px;
}
.page-inner-layout {
  /*top: 80px;*/
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.page-layout-cont-inner {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100%;
  height: 100%;
  position: relative;
  align-content: flex-start;
  justify-content: space-around;
}
.page-layout-cont {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #959595;
}
.drag-zone-cont {
  width: 100%;
  height: 100%;
  position: relative;
}
.sidebar-button {
  margin: 12px;
  width: 50px;
  background-color: #00cae3;
}
.pages-side-bar {
  position: relative;
  height: 100%;
  width: 240px;
  background-color: #2d2828;
  box-shadow: 0 1px 12px black;
  z-index: 1;
}
.action-side-bar {
  position: relative;
  right: 0;
  height: 100%;
  width: 100px;
  background-color: #ffffff;
  box-shadow: 0 -1px 10px #626262;
}
.action-side-bar-inner {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 20px;
  align-content: flex-start;
  justify-content: space-around;
}
.pages-side-bar-inner {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-around;
}
.page-mockup {
  background-color: #fafafa;
  position: relative;
  width:  160px;
  height: 100px;
  margin: 20px 0;
  padding: 8px;
  padding-right: 15px;
  cursor: pointer;
}
.del-page {
  cursor: pointer;
  position: absolute;
  top: -4px;
  right: -10px;
  text-align: center;
  font-size: small;
  color: #FFFFFF;
  width: 20px;
  border-radius: 20px;
  height: 20px;
  background-color: #f97900;
}
.down-page {
  cursor: pointer;
  position: absolute;
  top: 52px;
  right: -10px;
  text-align: center;
  font-size: small;
  color: #00ffff;
  width: 20px;
  border-radius: 4px;
  height: 20px;
  background-color: #00b2f9;
}
.edit-page {
  cursor: pointer;
  position: absolute;
  top: 86px;
  right: -10px;
  text-align: center;
  font-size: small;
  color: #00ffff;
  width: 20px;
  border-radius: 9px;
  height: 20px;
  background-color: #7eb230;
}
.up-page {
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: -10px;
  text-align: center;
  font-size: small;
  color: #00ffff;
  width: 20px;
  border-radius: 4px;
  height: 20px;
  background-color: #00b2f9;
}
.active-slide {
  outline: 3px solid #48c666;
}
</style>
