<template>
  <div
    :tabindex="table.info.id"
    class="tbi-none"
    :class="vcentred ? 'vcentred' : ''"
  >
    <v-row>
      <v-col v-if="showTableTitle">
        <h2 class="mb-2">
          {{ table.info.title }}
        </h2>
      </v-col>
    </v-row>
    <v-data-table
      v-if="table.info.id && (table.data || presentation)"
      :ref="'table-' + table.info.id"
      :items="rows"
      :headers="headersPerform"
      resize="false"
      row-class="myRowClass"
      class="cell-border"
      :items-per-page="-1"
      hide-default-footer
      hide-default-header
      no-data-text="нет данных"
    >
      <template #body>
        <template v-if="!headersHaveGroup">
          <tr>
            <td
              v-for="col in headersPerform"
              :key="col.value+''"
              :style="compileHeight(col.rowspan, headerStyles.height) + 'width:' + col.width + ';'"
            >
              <div
                class="header-cell"
                :style="compileHeight(col.rowspan, headerStyles.height) + headerStyles.styleString"
              >
                <div
                  :class="headerStyles.additionClass"
                  :style="headerStyles.innerStyleObj"
                >
                  {{ col.text }}
                </div>
              </div>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td
              v-for="col in headersGroups.cols"
              :key="col.value+''"
              :rowspan="col.rowSpan"
              :colspan="headersGroups.groups[col.text]"
              :style="compileHeight(col.rowspan, headerStyles.height) + 'width:' + col.width + ';'"
            >
              <div
                class="header-cell"
                style="height: 100%"
                :style="headerStyles.styleString"
              >
                <div
                  :class="headerStyles.additionClass"
                  :style="headerStyles.innerStyleObj"
                >
                  {{ col.text }}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td
              v-for="col in headersCols"
              :key="col.value+''"
              :rowspan="col.rowSpan"
              :colspan="col.colSpan"
              :style="compileHeight(col.rowspan, headerStyles.height) + 'width:' + col.width + ';'"
            >
              <div
                class="header-cell"
                style="height: 100%"
                :style="headerStyles.styleString"
              >
                <div
                  :class="headerStyles.additionClass"
                  :style="headerStyles.innerStyleObj"
                >
                  {{ col.text }}
                </div>
              </div>
            </td>
          </tr>
        </template>
        <template v-for="(item, index) in rows">
          <tr
            v-if="table.row[index].flgvisible !== 0"
            :key="'row-' + index"
            :class="table.row[index].flgdynamic === 1 ? 'can-reorder' : ''"
          >
            <td
              v-for="(col, colindex) in headersPerform"
              :key="col.value"
              style="height: 1px;"
            >
              <div
                class="cell"
                :class="[!!item[col.value].flgrightsadvanced ? 'triangle': '', canEdit && item[col.value].can_edit ? 'canEdit': '', chosenCell && chosenCell.colIndex === colindex && chosenCell.rowIndex === index ? 'chosen' : '']"
                :style="addStyles(item[col.value], getRowHeight(item[col.value].pldrowid)).styleString"
              >
                <div
                  :class="addStyles(item[col.value]).additionClass"
                  :style="addStyles(item[col.value]).innerStyleObj"
                >
                  <span v-if="!(hideAdvancedData && !!item[col.value].flgrightsadvanced)">
                    {{ mainValueFormatted(item[col.value]) }}
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </template>
    </v-data-table>
    <div
      v-if="showInfoValue && rightadmin"
      class="addition-info"
    >
      <p>
        {{ addLineBreak(showInfoValue) }}
      </p>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'VuetyfiTable',
    props: {
      hideAdvancedData: {
        type: Boolean,
        default: false,
      },
      table: {
        type: Object,
        default: () => {},
      },
      tableCurrent: {
        type: Object,
        default: () => {},
      },
      vcentred: {
        type: Boolean,
        default: false,
      },
      presentation: {
        type: Boolean,
        default: false,
      },
      canEdit: {
        type: Boolean,
        default: false,
      },
      showTableTitle: {
        type: Boolean,
        default: false,
      },
      rightadmin: {
        type: Boolean,
        default: false,
      },
      rightadvanced: {
        type: Boolean,
        default: false,
      },
      gettingObjects: {
        type: Boolean,
        default: false,
      },
      activeTable: {
        type: Number,
        default: null,
      },
    },
    data () {
      return {
        snapshotDialog: false,
        snapshotlist: [],
        tableSnap: {},
        showInfoValue: null,
        dev: true,
        objectsTitle: null,
        popupItem: null,
        popupItemValueSnap: null,
        objectsAddDialog: false,
        objectsDialog: false,
        rowsToDelete: {},
        virtualObject: {
          id: null,
          title: null,
          show: false,
        },
        selectedCell: null,
        chosenCell: null,
        valueСSnap: null,
        valueSnap: null,
        tableFix: false,
      }
    },
    computed: {
      ...mapGetters(['tableStyles']),
      listNotStatic () {
        if (this.popupItem?.pldcolid) {
          const col = this.table.col.find(x => x.plcolid === this.popupItem.pldcolid)
          if (col.pllistflgcommon == 1) {
            return false
          } else {
            return true
          }
        }
        return false
      },
      objectsList () {
        if (!this.popupItem) return {}
        const objList = this.$store.getters.getObjectsList[this.popupItem.colpllistid].data
        if (objList) {
          objList.forEach((item) => {
            item.searchString = item.title + ' (id:' + item.listitemdataid + ')'
          })
          return objList
        } else {
          return {}
        }
      },
      headerStyles () {
        let styleString = ''
        const styleObj = {}
        const styles = this.presentation ? this.table.info.header_presentation_stylesid || this.table.info.header_stylesid : this.table.info.header_stylesid
        let innerStyleObj = null
        let additionClass = null
        if (styles) {
          const styleArr = styles.toString().split(',')
          styleArr.forEach((style) => {
            for (const key in this.tableStyles[style]) {
              if (key == 'pl-vertical-align') {
                if (!additionClass) additionClass = {}
                if (this.tableStyles[style][key] === 'middle') {
                  additionClass = 'v-middle'
                  styleObj.position = 'relative'
                } else if (this.tableStyles[style][key] === 'bottom') {
                  additionClass = 'v-bottom'
                  styleObj.position = 'relative'
                }
              } else if (key == 'padding') {
                if (!innerStyleObj) innerStyleObj = {}
                innerStyleObj[key] = this.tableStyles[style][key]
              } else if (key == 'font-family') {
                console.log('font', this.tableStyles[style][key])
                if (this.tableStyles[style][key] === 'Calibri') {
                  styleObj[key] = '\'Open Sans\', sans-serif;'
                }
              } else {
                styleObj[key] = this.tableStyles[style][key]
              }
            }
          })
          styleString = JSON.stringify(styleObj)
            .replace(/,/g, ';')
            .replace(/"/g, '')
            .replace('{', '')
            .replace('}', '')
        }
        const height = this.table.info?.headerheight?.replace('px', '')
        return { styleString, innerStyleObj, additionClass, height }
      },
      tableStyles () {
        return this.$store.getters.tableStyles
      },
      rows () {
        let rows = null
        rows = []
        this.table.data.forEach((row, indx) => {
          rows[indx] = {}
          for (const col in row) {
            rows[indx][col] = row[col]
          }
        })
        return rows
      },
      headersHaveGroup () {
        const haveGroup = this.table.col.find(x => x.grouptitle)
        return !!haveGroup
      },
      headersGroups () {
        const cols = []
        const groups = {}
        if (this.headersHaveGroup) {
          this.table.col.forEach((col) => {
            if (col.grouptitle) {
              if (groups[col.grouptitle]) {
                groups[col.grouptitle]++
              } else {
                groups[col.grouptitle] = 1
                cols.push({
                  value: col.id,
                  colstyles: col.colstyles,
                  width: col.width,
                  text: col.grouptitle,
                  grouptitle: col.grouptitle,
                })
              }
            } else {
              cols.push({
                value: col.id,
                text: col.title,
                colstyles: col.colstyles,
                rowSpan: 2,
                width: col.width,
              })
            }
          })
        }
        return { cols: cols, groups: groups }
      },
      headersCols () {
        const cols = []
        if (this.headersHaveGroup) {
          this.table.col.forEach((col) => {
            if (col.grouptitle) {
              cols.push({
                value: col.id,
                text: col.title,
                colstyles: col.colstyles,
                width: col.width,
                grouptitle: col.grouptitle,
              })
            }
          })
        }
        return cols
      },
      headersPerform () {
        const cols = []
        this.table.col.forEach((col) => {
          cols.push({
            value: col.id,
            text: col.title,
            colstyles: col.colstyles,
            rowspan: col.rowspan,
            width: col.width,
            grouptitle: col.grouptitle,
          })
        })
        if (this.haveDelRow && this.canEdit) {
          cols.push({
            value: 'del',
            text: '',
            colstyles: cols[0].colstyles,
            rowspan: cols[0].rowspan,
            width: '30px',
          })
        }
        return cols
      },
    },
    methods: {
      mainValueFormatted (value) {
        if (value.main_value === '-') {
          return value.main_value
        }
        if (value.datamask && value.main_datatype !== 'String') {
          const valmask = value.datamask
          const maskType = valmask.substr(1, 1)
          const nullChange = valmask.substr(2, 2)
          if (!value.main_value) {
            if (value.main_value === null) {
              if (nullChange === 'nn' || nullChange === 'nd') return '-'
              if ((nullChange === 'nz' || nullChange === 'ze') && maskType === 'p') return '0%'
              if (nullChange === 'nz') return '0'
            }
            if (value.main_value === 0 || value.main_value === '0') {
              if (nullChange === 'ze' && maskType === 'p') return ''
              if (nullChange === 'ze') return ''
            }
            return value.main_value
          }
          if (maskType === 'i') {
            return this.$func.numberWithSpaces(parseInt(value.main_value))
          }
          if (maskType === 'f') {
            const roundingVol = valmask.substr(4) || valmask.substr(2)
            if (!roundingVol) {
              return this.$func.numberWithSpaces(parseFloat(value.main_value))
            }
            return this.$func.numberWithSpaces(parseFloat(value.main_value).toFixed(parseInt(roundingVol, 10)))
          }
          if (maskType === 'p') {
            const roundingVol = valmask.substr(4) || valmask.substr(2)
            if (this.edit) {
              if (this.lastdot) {
                return (parseFloat(value.main_value) * 100).toFixed(parseInt(roundingVol, 10)) + '.'
              } else {
                return (parseFloat(value.main_value) * 100).toFixed(parseInt(roundingVol, 10))
              }
            } else {
              return this.$func.numberWithSpaces((parseFloat(value.main_value) * 100).toFixed(parseInt(roundingVol, 10))) + '%'
            }
          }
        }
        return value.main_value
      },
      addStyles (item, rowHeight) {
        let styleString = ''
        const styleObj = {}
        const styles = this.presentation ? item.styles_presentation : item.styles
        let innerStyleObj = null
        let additionClass = null
        if (styles) {
          const styleArr = styles.split('-')
          styleArr.forEach((style) => {
            for (const key in this.tableStyles[style]) {
              if (key == 'pl-vertical-align') {
                if (!additionClass) additionClass = {}
                if (this.tableStyles[style][key] === 'middle') {
                  additionClass = 'v-middle'
                  styleObj.position = 'relative'
                } else if (this.tableStyles[style][key] === 'bottom') {
                  additionClass = 'v-bottom'
                  styleObj.position = 'relative'
                }
              } else if (key == 'padding') {
                if (!innerStyleObj) innerStyleObj = {}
                innerStyleObj[key] = this.tableStyles[style][key]
              } else {
                styleObj[key] = this.tableStyles[style][key]
              }
            }
          })
          // styleObj['justify-content'] = 'center'
          styleString = JSON.stringify(styleObj)
            .replace(/,/g, ';')
            .replace(/"/g, '')
            .replace('{', '')
            .replace('}', '')
        }
        if (rowHeight) {
          styleString = 'height: ' + rowHeight + '; ' + styleString
        }
        return { styleString, innerStyleObj, additionClass }
      },
      compileHeight (rowspan, height) {
        let hString = ''
        if (rowspan) {
          hString = (height * 2) + 1
        } else {
          hString = height
        }
        return 'height: ' + hString + 'px;'
      },
      getRowHeight (pldrowid) {
        const row = this.table.row.find(x => {
          return x.plrowid === pldrowid
        })
        if (row && row.height) {
          return row.height
        } else {
          return this.table.info.rowdefaultheight
        }
      },
    },
  }
</script>

<style>
.triangle {
  overflow: hidden;
  position: inherit;
}
.triangle::after {
  content: ' ';
  position: absolute;
  top: -2px;
  left: -45px;
  width: 40px;
  height: 20px;
  -webkit-transform: rotate(45deg) translate(-3px, -28px);
  background: #ff9797;
}

@font-face {
  font-family: "Calibri";
  src: local("Calibri"), url("/fonts/Calibri/Calibri.ttf") format("truetype");
}
.addition-info {
  all: initial;
  position: fixed;
  display: block;
  top: 20px;
  right: 30px;
  background-color: rgba(0, 202, 227, 0.86);
  width: 160px;
  height: 200px;
  z-index: 2000;
  word-break: break-word;
  white-space: break-spaces;
  padding: 8px;
}

.tbi-none {
  outline:none;
}
.vcentred {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}
.chosen {
  border: 1px solid #4896da;
}
.canEdit {
  cursor: pointer;
}
.v-middle {
  /*position: absolute!important;*/
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.cell-input {
  width: 100%;
  height: 100%;
  padding-left: 4px;
}
.withoutbg {
  background: none;
}

.select-border {
  background-color: #2d6da3;
}
.header-cell {
  width: 100%;
  height: 100%;
}
.cell-border table{
  clear: both;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}

.cell-border th {
  border: 2px solid black!important;
  padding: 0!important;
}
/*.cell-border thead tr th:first-child, .cell-border tbody tr td:first-child {*/
/*  border-left: 1px solid rgba(0, 0, 0, 1);*/
/*}*/
/*.cell-border thead tr th {*/
/*  padding: 0!important;*/
/*}*/
.cell-border tr td {
  border: 2px solid black!important;
  padding: 0!important;
  overflow: hidden;
}
/*.cell-border tbody td {*/
/*  border-top: 1px solid rgba(0, 0, 0, 1);*/
/*  border-right: 1px solid rgba(0, 0, 0, 1);*/
/*}*/

.list .row {
  display: flex;
}

revogr-viewport-scroll.col {
  padding: 0;
}
.checkit{
  background-color: salmon;
}
.row.fit {
  display: block;
  margin: 0;
  text-align: center;
}
.cell {
  width: 100%;
  height: 100%;
}

</style>
