<template>
  <v-dialog
    v-model="dataLoading"
    persistent
    width="300"
  >
    <v-card>
      <v-card-text>
        <v-progress-linear
          :color=colorLinear
          indeterminate
          rounded
          height="6"
          v-if="loaderBase"
        />
        <div
          v-else
          class="d-flex justify-center align-center"
        >
          <v-progress-circular
            d-flex
            margin="0 auto"
            indeterminate
            :color=colorCircular
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'DataLoadingIndicator',
    props: {
      loaderBase: {
        type: Boolean,
        default: true,
      },
      dataLoadingHard: {
        type: Boolean,
        default: null,
      },
      colorLinear: {
        type: String,
        default: 'green accent-4',
      },
      colorCircular: {
        type: String,
        default: 'primary',
      },
    },
    computed: {
      dataLoading () {
        if (this.dataLoadingHard !== null) return this.dataLoadingHard
        return this.$store.getters.dataLoading
      },
    },
  }
</script>
