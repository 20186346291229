<template>
  <span>
    <v-dialog
      :value="mainDialog"
      persistent
      overlay-opacity="0.96"
      max-width="750"
    >
      <v-card>
        <v-card-text>
          <v-form>
            <v-select
              v-model="chosenCat"
              :items="planningCategories"
              item-value="id"
              item-text="title"
              label="Категория"
              :disabled="catLoading"
              :loading="catLoading"
              @change="getTemplate"
            />
            <v-select
              v-model="chosenRep"
              :items="planningReports"
              item-value="id"
              item-text="title"
              label="Шаблон отчета"
              :loading="plreportsLoading"
              :disabled="planningReports.length === 0 || plreportsLoading"
              @change="getAreasList"
            />
            <v-select
              v-model="selectedArea"
              :items="areasListSelected"
              return-object
              item-text="title"
              label="Центр учета"
              :loading="areasLoading"
              :disabled="areasListSelected.length === 0 || areasLoading"
              @change="getPlTemplate"
            />
            <v-row
              v-if="templateLoad && templateSelected && templateSelected.length > 0"
            >
              <v-col class="d-flex">
                <v-select
                  v-model="chosenPlTemplate"
                  item-value="id"
                  item-text="title"
                  :items="templateSelected"
                  label="Шаблон презентации"
                  :disabled="(templateSelected.length === 0) || (createNewPr || editPr)"
                />
                <v-btn
                  v-if="chosenPlTemplate !== null && !editPr"
                  icon
                  elevation="0"
                  class="mx-0 mt-3 white"
                  @click="editTemplate(chosenPlTemplate)"
                >
                  <v-icon large>
                    mdi-pencil-circle-outline
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="!(createNewPr || editPr)"
                  icon
                  elevation="0"
                  class="mx-0 mt-3 white"
                  @click="createTemplate()"
                >
                  <v-icon large>
                    mdi-plus-circle-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-row v-if="createNewPr || editPr || (templateLoad && templateSelected.length === 0)">
            <v-col
              cols="12"
              class="text-center justify-center mt-5 pb-0 d-flex justify-center"
            >
              <div
                class="text-subtitle-2 align-center d-flex"
              >
                {{ editPr ? 'Редактировать шаблон презентации' : 'Создать шаблон презентации' }}
              </div>
              <v-spacer />
              <v-btn
                v-if="templateSelected.length > 0"
                icon
                elevation="0"
                class="mx-0 mt-0 white"
                @click="cancelEditTemplate(chosenPlTemplate)"
              >
                <v-icon large>
                  mdi-close-circle-outline
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="presentationTitle"
                label="Название шаблона презентации"
              />
            </v-col>
            <v-col>
              <v-textarea
                v-model="presentationHelptext"
                label="Описание"
                rows="1"
              />
            </v-col>
            <v-col cols="12">
              Фон презентации
              <v-img
                v-if="bgImage"
                max-height="150"
                max-width="250"
                :src="bgImage"
                aspect-ratio="1.7"
                contain
              />
            </v-col>
            <v-col>
              <v-file-input
                truncate-length="15"
                label="Сменить фон презентации"
                @change="uploadImage($event)"
              />
            </v-col>
            <v-col cols="12">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="prDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="prDate"
                    label="С даты"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="prDate"
                  locale="ru-ru"
                  :first-day-of-week="1"
                  no-title
                  scrollable
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(prDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="cyan lighten-3"
            class="cyan--text text--darken-4"
            @click="chancel"
          >
            Отмена
          </v-btn>
          <v-spacer />
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="disableNextStep"
            @click="nextStep"
          >
            Продолжить
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>

  export default {
    name: 'ChoosePresentationDialog',
    props: ['mainDialog'],
    data () {
      return {
        bgImage: null,
        globalTemplate: null,
        editPr: false,
        createNewPr: false,
        menu: false,
        templateLoad: false,
        chosenCat: null,
        areasLoading: false,
        planningCategories: [],
        planningReports: [],
        chosenPlTemplate: null,
        catLoading: true,
        chosenRep: null,
        plreportsLoading: false,
        selectedArea: null,
        presentationHelptext: null,
        prDate: null,
        presentationTitle: '',
        areasListSelected: [],
        templateSelected: [],
      }
    },
    computed: {
      disableNextStep () {
        return false
      },
      createNewPrTp () {
        return this.templateLoad && this.templateSelected && this.selectedArea && this.templateSelected.length === 0
      },
    },
    mounted () {
      this.$store.dispatch('getPlanningCategories').then((resp) => {
        this.planningCategories = resp
        this.catLoading = false
      }).catch((e) => {
        console.log('Error:', e)
        this.catLoading = false
      })
    },
    methods: {
      getBase64 (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = function () {
            resolve(reader.result)
          }
          reader.onerror = function (error) {
            reject(error)
          }
        })
      },
      uploadImage (event) {
        this.getBase64(event).then((resp) => {
          this.bgImage = resp
        })
      },
      yymmddFormat (date) {
        if (date) {
          const [dd, mm, yy] = date.split('.')
          return yy + '-' + mm + '-' + dd
        } else {
          return null
        }
      },
      chancel () {
        this.$emit('chancel')
      },
      createTemplate () {
        this.chosenPlTemplate = null
        this.createNewPr = true
      },
      cancelEditTemplate () {
        this.presentationTitle = null
        this.presentationHelptext = null
        this.prDate = null
        this.editPr = false
        this.createNewPr = false
      },
      editTemplate (templateID) {
        console.log('aa', templateID)
        const index = this.templateSelected.findIndex(x => x.id === templateID)
        this.presentationTitle = this.templateSelected[index].title
        this.presentationHelptext = this.templateSelected[index].helptext
        console.log('aa1', this.templateSelected[index])
        this.templateSelected[index].globaltemplate = JSON.parse(this.templateSelected[index].globaltemplate.replace(/'/g, '"'))
        this.bgImage = this.templateSelected[index].globaltemplate.backgroundImage
        this.prDate = this.yymmddFormat(this.templateSelected[index].fromdate)
        this.editPr = true
      },
      nextStep () {
        if (this.createNewPrTp || this.editPr) {
          this.addPlTemplate().then((PlTemplate) => {
            const payload = {
              plreportid: this.chosenRep,
              plareaid: this.selectedArea.areaid,
            }
            this.$store.dispatch('getPlanningPrList', payload).then((prTemplates) => {
              this.templateLoad = true
              this.templateSelected = prTemplates
              const present = this.templateSelected.find(x => x.id === PlTemplate.presid)
              this.$emit('getPrPages', { plt: PlTemplate.presid, cr: this.chosenRep, area: this.selectedArea.areaid, present: present })
            })
          }).catch((e) => {
            console.log('Error', e)
          })
        } else if (this.chosenPlTemplate !== null) {
          const present = this.templateSelected.find(x => x.id === this.chosenPlTemplate)
          this.$emit('getPrPages', { plt: this.chosenPlTemplate, cr: this.chosenRep, area: this.selectedArea.areaid, present: present })
        }
      },
      getAreasList (plreportid) {
        this.areasLoading = true
        this.templateLoad = false
        this.clearSlected()
        this.planningTables = {}
        this.areasListSelected = []
        this.versions = {}
        this.$store.dispatch('getPlanningAreasList', { plreportid: plreportid }).then((resp) => {
          this.areasListSelected = resp
          this.areasListSelected.unshift({ areaid: null, title: 'для всех' })
          this.areasLoading = false
        })
      },
      getTemplate (newval) {
        this.planningTables = {}
        this.plreportsLoading = true
        this.templateLoad = false
        this.chosenRep = null
        // this.clearSlected()
        this.areasListSelected = []
        this.versions = {}
        this.$store.dispatch('getPlanningReportsList', { plcategoryid: newval }).then((resp) => {
          this.planningReports = resp
          if (this.planningReports.length === 1) {
            this.chosenRep = this.planningReports[0].id
            this.getAreasList(this.chosenRep)
          }
          this.plreportsLoading = false
        }).catch((e) => {
          console.log('Error: ', e)
          this.plreportsLoading = false
        })
      },
      getPlTemplate (selectedArea) {
        this.templateLoad = false
        const payload = {
          plreportid: this.chosenRep,
          plareaid: selectedArea.areaid || 'None',
        }
        this.$store.dispatch('getPlanningPrList', payload).then((prTemplates) => {
          console.log('шаблоны презентаций', prTemplates)
          this.templateLoad = true
          this.templateSelected = prTemplates
        })
      },
      clearSlected () {
        this.selectedPeriod = null
        this.selectedArea = null
        this.selectedVers = null
        this.chosenPlTemplate = null
        this.templateLoad = false
      },
      addPlTemplate () {
        return new Promise((resolve, reject) => {
          const payload = {
            plreportid: this.chosenRep,
            title: this.presentationTitle,
            helptext: this.presentationHelptext,
            plareaid: this.selectedArea.areaid,
            datefrom: this.prDate ? this.prDate : '',
          }
          if (this.chosenPlTemplate) {
            payload.versionid = this.chosenPlTemplate
          }
          if (this.bgImage) {
            this.globalTemplate = { backgroundImage: this.bgImage }
          }
          if (this.globalTemplate) {
            payload.blobpattern = JSON.stringify(this.globalTemplate)
          }
          this.$store.dispatch('addPlanningPrList', payload).then((prTemplates) => {
            this.templateSelected = prTemplates.res
            resolve(prTemplates.res)
          }).catch((e) => {
            reject(e)
          })
        })
      },
    },
  }
</script>
